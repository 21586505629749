<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout, VclList, Stat },
  data() {
    return {
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  methods: {
    getList() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("investments/contracts")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
            this.statData = [
              {
                title: "Total Aportado",
                value: this.$options.filters.currency(
                  response.data.total.contracts
                ),
              },
              {
                title: "Rendimentos Totais",
                value: this.$options.filters.currency(
                  response.data.total.profits
                ),
              },
            ];
          } else if (response.data.status == "empty") {
            this.statData = [];
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Meus Contratos</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="table.errored">
          {{
          t(
          "We apologize, we are unable to retrieve information at this time. Please try again later."
          )
          }}
        </div>
        <div v-else-if="table.empty">{{ t("No records found.") }}</div>
        <div v-else class="table-responsive">
          <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
          <table v-else class="table table-nowrap">
            <thead class="thead-light">
              <tr>
                <th>#</th>
                <th>Período</th>
                <th>Valor</th>
                <th>Rendimentos</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td class="align-middle">
                  <strong>#{{ td.id.split('-')[0] }}</strong><br>
                  <span class="font-size-11 text-uppercase">{{ td.date_added }}</span>
                </td>
                <td class="align-middle">
                  {{ td.period }} dias<br>
                  <b-progress :value="td.progress" :max="td.period" class="mb-0" height="20px"></b-progress>
                  <span class="text-primary font-size-11 text-uppercase" v-if="td.status == 'approved'">+ Carência em andamento</span>
                </td>
                <td class="align-middle">{{ td.value | currency }}</td>
                <td class="align-middle">{{ td.profits | currency }}</td>
                <td class="align-middle">
                  <span v-if="td.status == 'approved'" class="p-3 px-4 badge badge-soft-success font-size-14">{{ t("Em andamento") }}</span>
                  <span v-else-if="td.status == 'finalized'" class="p-3 px-4 badge badge-soft-info font-size-14">{{ t("Finalizado") }}</span>
                </td>
                <td class="align-middle">
                  <router-link :to="'/investments/contracts/' + td.id" class="btn btn-light py-1 px-2">
                    <i class='bx bx-show font-size-22 align-middle'></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.table tbody tr td {
  padding: 0.8rem 0.75rem;
}
</style>